import React from "react";
import "./volunteer.css";

const Volunteer = () => {
  return (
    <>
      <section id="sub-header">
        <div className="container">
          <div className="header-content">
            <h1>Become a Volunteer</h1>
            <p>Requirements to be a member of Our Desiree</p>
          </div>
          <div className="right-foot-img"></div>
        </div>
      </section>
      <section id="v-requiremnts">
        <div className="container">
          <div className="r-list">
            <ul>
              <li>Must be 18 years and above.</li>
              <li>Must be able to read and write.</li>
              <li>Must be positive minded.</li>
              <li>Must be a participator not a spectator.</li>
              <li>Must know its a voluntary organization.</li>
              <li>Must be accommodative towards other members.</li>
              <li>Must be ready to contribute to any outreach programs.</li>
              <li>Must have the interest of the organization at heart.</li>
              <li>
                Must be law abiding to the rules and regulations governing the
                organization.
              </li>
              <li>
                Must be able to share ideas and suggestions that aids the
                progress of the organization.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Volunteer;
