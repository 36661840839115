import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// import { galleryImages } from "../../constants/data";
// import { photos } from "./photos";

import "./gallery.css";

const ImagesGallery = ({ galleryImages }) => {
  // Define the image limit and the number of images to display initially
  const imageLimit = 15;
  const [displayedImages, setDisplayedImages] = useState(
    galleryImages.slice(0, imageLimit)
  );

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const loadMoreImages = () => {
    // Calculate the next batch of images to display
    const nextBatch = galleryImages.slice(
      displayedImages.length,
      displayedImages.length + imageLimit
    );

    // Update the displayed images state with the new batch
    setDisplayedImages((prevImages) => [...prevImages, ...nextBatch]);
  };

  // Determine if there are more images to load
  const hasMoreImages = displayedImages.length < galleryImages.length;

  return (
    <div className="gallery-container">
      <Gallery photos={displayedImages} onClick={openLightbox} />
      {hasMoreImages && (
        <button className="btnPrimary" onClick={loadMoreImages}>
          Load More
        </button>
      )}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={displayedImages.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default ImagesGallery;
