import React from "react";
import { useParams } from "react-router-dom";
import "./event-detail.css";
import { Gallery, ScrollToTop, YoutubeFrame } from "../../components";
import { projects } from "../../constants/data";
import ImagesGallery from "../../components/Gallery";

const EventDetail = () => {
  const { eventId } = useParams();
  //   console.log("eventId:", eventId);

  const ourProjects = projects.find((project) => project.id === eventId);
  //   console.log("ourProjectsOutput:", ourProjects);

  return (
    <>
      <section className="eventDetailSection">
        <div className="container">
          <div className="eventHeader hor">
            <div className="left ver flex">
              <div className="eventTitle">
                <h1>{ourProjects.title}</h1>
              </div>
            </div>
            <div className="right ver flex">
              <div className="imgWrapper">
                <img src={ourProjects.image} alt={ourProjects.title} />
              </div>
            </div>
          </div>
          <div className="eventDetail">
            <p>{ourProjects.details}</p>
          </div>
          {ourProjects.youtubeURL ? (
            <div className="eventVideo">
              <YoutubeFrame link={ourProjects.youtubeURL} />
            </div>
          ) : (
            ""
          )}
          <div className="eventPictures">
            <div className="header">
              <h3>Gallery</h3>
            </div>
            {/* <Gallery galleryImages={ourProjects.pictures} /> */}
            <ImagesGallery galleryImages={ourProjects.pictures} />
          </div>
        </div>
      </section>

      <ScrollToTop />
    </>
  );
};

export default EventDetail;
