import React from "react";
import ScrollToTop from "../../components/ScrollToTop";

const TermsAndConditions = () => {
  return (
    <>
      <section className="policy pt-6">
        <div className="container pt-4">
          <div className="policyTextContent">
            <div className="policyContentHeader">
              <h1>Terms and conditions</h1>
            </div>
            <div className="policyContent">
              <section id="tnc">
                <div class="terms">
                  <h2>Terms</h2>
                  <p>
                    By using or visiting this Site, you agree to (1) the terms
                    and conditions expressed on this webpage; (2) our privacy
                    policy found at Privacy & Security; and (3) all other terms
                    referenced herein. IF YOU DO NOT AGREE TO ANY OF THESE
                    TERMS, DO NOT USE THE SITE.
                  </p>
                </div>
                <div class="conditions">
                  <h2>conditions</h2>
                  <p>
                    Our Desiree may, at its sole discretion, modify or revise
                    these Site Terms & Conditions and its related policies at
                    any time, and you agree to be bound by such modifications.
                    Any modifications will be effective immediately upon posting
                    of the revisions on the Site, and you waive any right you
                    may have to receive specific notice of such modifications.
                    Your continued use of this Site following the posting of
                    changes or modifications will confirm your acceptance of
                    such changes or modifications. Therefore, you should
                    periodically review the Site Terms & Conditions and
                    applicable policies from time to time to understand the
                    terms and conditions that apply to your use of the Site.
                  </p>
                </div>
                <div class="commitment">
                  <h2>Our Commitment</h2>
                  <p>
                    Our Desiree is committed to protecting the privacy of the
                    children and adults living in the communities we serve. As
                    part of this commitment, The Organization prohibits the use
                    of any child photos as displayed on the Site on any other
                    websites, whether personal or commercial. Our Desiree is
                    also committed to protecting the privacy of its donors,
                    supporters, employees, and other stakeholders. For more
                    information on Our Desiree’s Privacy and Security Policy,
                    please click here Privacy & Security.
                  </p>
                </div>
                <div class="content">
                  <h2>content</h2>
                  <p>
                    The content, data, images, and videos found on this Site are
                    the property of, or under license to, Our Desiree and may
                    not be redistributed, copied or modified, altered, or used
                    without the express prior written permission of The
                    Organization
                    <br />
                    <br />
                    The use of, modification of, or linking to any images,
                    photos, videos, stories, and other content found on this
                    Site, without the express prior written permission of Our
                    Desiree is strictly prohibited.
                    <br />
                    <br />
                    From time to time, the information displayed on this Site
                    may be incorrect or incomplete. By using the Site, you agree
                    to hold Our Desiree, its directors, officers, employees,
                    volunteers, or related affiliates harmless from any damages
                    resulting from any such errors or omissions.
                    <br />
                    <br />
                    The Site may contain links to other third-party websites.
                    Our Desiree is not responsible for the privacy practices or
                    the content of any of those third-party websites.
                  </p>
                </div>
                <div class="termination-policy">
                  <h2>Termination Policy</h2>
                  <p>
                    Our Desiree may at its sole discretion limit access to the
                    Site and/or terminate the accounts of any subscribers,
                    account holders, or users who may be in violation of any
                    intellectual property rights of others and whose activities
                    may be in contravention of these Terms & Conditions.
                  </p>
                </div>
                <div class="trademarks">
                  <h2>trademarks</h2>
                  <p>
                    The “Our Desiree” trademarks and trade names along with the
                    other logos, service marks, slogans, and any other indicia
                    for the source of goods and services (collectively the
                    “Marks”) and trade dress contained on the Site are unless
                    otherwise stated, owned and/or used under license by Our
                    Desiree or their affiliates (collectively “Our Desiree”) and
                    may not be copied, imitated or used, in whole or in part,
                    without the prior written permission of Our Desiree. You may
                    not use any metatags or any other “hidden text” utilizing
                    the Marks or trade dress of World Vision without our prior
                    written permission. All other trademarks, trade names,
                    logos, service marks, and slogans mentioned on the Site are
                    the property of their respective owners. Reference to any
                    third-party trademarks, trade names, logos, service marks,
                    products, services, processes, other information, or
                    otherwise does not constitute or imply endorsement,
                    sponsorship, or recommendation thereof by Our Desiree.
                  </p>
                </div>
                <div class="general">
                  <h2>general</h2>
                  <p>
                    These Terms & Conditions are subject to change without
                    notice. Our Desiree reserves the sole right to determine how
                    its resources, the Site, and Marks may be used. Decisions
                    regarding third-party use and linking are at the sole
                    discretion of Our Desiree and may be revised or rescinded
                    without prior notice.
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default TermsAndConditions;
