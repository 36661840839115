const titleMap = {
  "/": "",
  "/about-us": "About Us",
  "/contact-us": "Contact Us",
  "/our-team": "Team",
  "/volunteer": "Volunteer",
  "/donate": "Donate",
  "/privacy-policy": "Privacy Policy",
  "/cookie-policy": "Cookie Policy",
  "/terms-and-conditions": "Terms And Conditions",
  "/events": "Events",
};
/**
 * Returns the title of a page based on its route path.
 * @param {string} path - The path of the page route.
 * @returns {string} The title of the page with the site name appended.
 */
export const getTitleFromRoute = (path) => {
  if (titleMap[path]) {
    return `Our Desiree | ${titleMap[path]} `;
  }

  const pastEventRegex = /^\/past-events\/(\w+)$/;
  const upcomingEventRegex = /^\/upcoming-events\/(\w+)$/;

  if (pastEventRegex.test(path)) {
    return "Past Event | Our Desiree ";
  } else if (upcomingEventRegex.test(path)) {
    return "Upcoming Event | Our Desiree ";
  } else {
    return "Our Desiree ";
  }
};
