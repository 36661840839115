import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./event-card.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


import { past_events } from "./../../constants/data";

const EventCard = () => {
  const [itemOffset, setItemOffset] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const projectsPerPage = 10;

  const endOffset = itemOffset + projectsPerPage;
  const currentProjects = past_events.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(past_events.length / projectsPerPage);

  const handlePageClick = (event) => {
    const newOffset = event.selected * projectsPerPage;
    setItemOffset(newOffset);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setItemOffset(0); // Reset pagination when search query changes
  };

  return (
    <>
      <section className="eventCard">
        <div className="header">
          <h3>Past Events</h3>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by title..."
            className="search-bar"
          />
        </div>
        <div className="cardsWrapper">
          {currentProjects
            .filter((project) =>
              project.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((project) => (
              <Link
                to={`/events/past-events/${project.id}`}
                key={project.id}
                className="item"
              >
                <div className="card">
                  <div className="date">
                    <div className="day">{project.date.day}</div>
                    <small className="month">{project.date.month}</small>
                    <div className="year">{project.date.year}</div>
                  </div>
                  <div className="info">
                    <div className="image">
                      <img src={project.image} alt={project.title} />
                    </div>
                    <div className="details">
                      <div className="title">
                        <h2>{project.title}</h2>
                      </div>
                      <div className="location">
                        <p>{project.location}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
          nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        />
      </section>
    </>
  );
};

export default EventCard;
