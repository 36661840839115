import React, { useState } from "react";
import axios from "axios";
import "./contact-us.css";
import { ScrollToTop, Notification } from "../../components";
import { SERVER_URL } from "../../utils/constant";

import { Link } from "react-router-dom";

const ContactUs = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("success");
  const [submitButtonValue, setSubmitButtonValue] = useState("Send");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // console.log("Form Data:", formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.subject
    ) {
      setNotificationMessage("Please fill in all required fields.");
      setNotificationType("error");
      setShowNotification(true);
      return; // Exit the function if any required field is empty
    }

    setSubmitButtonValue("Sending...");

    try {
      const response = await axios.post(
        "https://mail-api.meshofmothers.org/send-desiree-email",
        // "http://127.0.0.1:4001/send-desiree-email",
        formData
      );
      console.log("Response Data:", response.data);
      setNotificationMessage(response.data.message);
      setNotificationType("success");

      setShowNotification(true);

      setSubmitButtonValue("Send");

      // Close the notification after 3 seconds
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);

      // Clear the form data after successful submission
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
      });
    } catch (error) {
      console.error("Error sending email:", error);
      setNotificationMessage("An error occurred. Please try again later.");
      setNotificationType("error");

      // Show the notification
      setShowNotification(true);
      setSubmitButtonValue("Send");
    }
  };
  return (
    <>
      <section id="sub-header">
        <div className="container">
          <div className="header-content">
            <h1>Contact Us</h1>
          </div>
          <div className="right-foot-img"></div>
        </div>
      </section>
      <section id="contact-wrapper">
        <div className="container">
          <div className="left">
            <div className="address">
              <p>
                <small>
                  HSE NO. C1/9A
                  <br />
                  NTHC SAKOMONO ESTATE
                  <br />
                  Accra, Ghana
                </small>
              </p>
              <div>
                <i className="bx bxs-phone"></i>
                <small>+233553007296</small>
              </div>
              <div>
                <i className="bx bxs-phone"></i>
                <small>+233549824571</small>
              </div>
              <div>
                <i className="bx bxs-phone"></i>
                <small>+233549824571</small>
              </div>
              <div>
                <i className="bx bx-mail-send"></i>
                <small>
                  <Link
                    style={{ color: "#ee1d52" }}
                    to={"mailto:contact@ourdesiree.com"}
                  >
                    {" "}
                    contact@ourdesiree.com
                  </Link>
                </small>
              </div>
            </div>
          </div>
          <div className="right">
            <form>
              <label for="fname">First Name</label>
              <input
                type="text"
                id="fname"
                name="firstName"
                required
                placeholder="Your name.."
                onChange={handleChange}
                value={formData.firstName}
              />
              <label for="lname">Last Name</label>
              <input
                type="text"
                id="lname"
                name="lastName"
                required
                placeholder="Your last name.."
                onChange={handleChange}
                value={formData.lastName}
              />
              <label for="email">E Mail</label>
              <input
                type="text"
                id="email"
                required
                name="email"
                placeholder="Your email.."
                onChange={handleChange}
                value={formData.email}
              />
              <label for="subject">Subject</label>
              <textarea
                id="subject"
                name="subject"
                required
                placeholder="Message.."
                onChange={handleChange}
                value={formData.subject}
              ></textarea>
              <button
                type="submit"
                onClick={handleSubmit}
                value="Send"
                className="sub-btn"
              >
                {submitButtonValue}
              </button>
            </form>
          </div>
        </div>
      </section>
      <ScrollToTop />
      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default ContactUs;
