import React from "react";

import "./donate.css";

import { ScrollToTop } from "../../components";

const DonatePage = () => {
  return (
    <>
      <section id="sub-header">
        <div className="container">
          <div className="header-content">
            <h1>Donate to us</h1>
            <p>
              YOUR DONATION WILL GO A LONG WAY TO GIVE HOPE AND MAKE AN IMPACT
              IN THE LIVES OF THESE NEEDY CHILDREN.
            </p>
          </div>
          <div className="right-foot-img"></div>
        </div>
      </section>
      <section id="help">
        <div className="container">
          <article>
            <h2>How Your Donation will help Us?</h2>
            <p>
              Today, our biggest challenge is finding funds to sew uniforms and
              buy shoes and other essentials enough to cater to the total number
              of needy kids in a given school. We want to as much as possible,
              prevent instances where some kids will get the items whereas
              others will not. Your gifts and support will help a lot in this
              regard.
              <br /> There are a lot of needy children out there across the
              Country and your gifts and support will also enable us to reach
              them wherever and whenever they need help most. As a non-profit,
              we depend on the support of donors to reach our mission of giving
              kids in need new shoes, bags, and uniforms to attend school with
              dignity and joy, prepared to learn, play, and thrive.
              <br /> Your generous donation will be a difference in the lives of
              these kids. We are grateful for your gift. Thank you for donating.
            </p>
          </article>
        </div>
      </section>
      <section id="donate_info">
        <div className="container">
          <div className="donation-list">
            <div>
              <h4>MTN Mobile Money</h4>
              <p>024 300 8236</p>
              <p>055 300 7296</p>
            </div>
            <div>
              <h4>Bank Account</h4>
              <h3>NIB Bank</h3>
              <p>Our Desiree</p>
              <p>3028081781701</p>
              <p>Tema Comm 9</p>
            </div>
            <div>
              <h4>GoFoundMe Link</h4>
              <p>
                <a
                  href="http://gofund.me/42bd4542"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  gofund.me/42bd4542
                </a>
              </p>
            </div>
          </div>
          <div className="donate-right">
            <div>
              <h4>To donate in kind please contact:</h4>
              <p>024 669 6062</p>
            </div>
            <div>
              <h4>Cash App</h4>
              <p>$juabenserwaa</p>
            </div>
            <div>
              <h4>Zelle Account</h4>
              <p>jserwaah389@gmail.com</p>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default DonatePage;
