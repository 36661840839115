import React from "react";
import "./about-us.css";
import { ScrollToTop } from "../../components";

const AboutUs = () => {
  return (
    <>
      <section id="aboutUs">
        <div className="container">
          <div className="aboutUs-content">
            <h1>About us</h1>
            <article>
              <p>
                We are an organization committed to a passion we share to reach
                out to needy children across the country and provide them with
                shoes, uniforms, school bags, and other essentials to make their
                childhood and schooling more exciting.
              </p>
              <p>
                It is no secret that a very noticeable sign of poverty is seen
                in the lack of a pair of decent shoes and bags etc.
                Unfortunately, children have to suffer excruciating stigma and
                ridicule from their mates for the lack of these. A feeling that
                steals away the joy and dignity of these kids causing them to
                lose their self-esteem and eventually shying away from school.
                The future as it stands is our children and what we feed them
                today will feed us tomorrow. In any possible way, we intend to
                feed them with hope, joy, and love and make schooling life fun
                and easier. We believe that a happy soul is one with happy feet
                for kids! And that is why shoes are the topmost item we donate.
              </p>
              <p>
                Since the creation of the organization, we have donated to two
                schools in the western and the central regions of Ghana
                providing (will provide a quantity of stuff given). Currently,
                we do this with the support of volunteers and donations from
                individuals. Shortly we hope to tackle other major issues like
                the lack of schools in some communities, but until then
              </p>
              <h3>How can you help?</h3>
              <ul>
                <li>Follow our Instagram page and share with your friends</li>
                <li>
                  Identify a school and provide info on the number of the
                  children
                </li>
                <li>Donate</li>
                <li>Have clothes, shoes, toys, etc.? you can donate.</li>
                <li>Be a volunteer</li>
              </ul>
            </article>
            <article className="mission">
              <h3>Mission</h3>
              <p>
                To empower underprivileged children and marginalized youth to
                break the cycle of poverty
              </p>
            </article>
            <article className="vision">
              <h3>Vision</h3>
              <p>
                We envision a world where no child is forced to walk on their
                bare feet due to poverty
              </p>
            </article>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default AboutUs;
