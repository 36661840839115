import React from "react";
import "./home.css";
import { homeContent } from "../../constants/data";
import {
  CardsRoller,
  EventCard,
  ScrollToTop,
  Slider,
  UpcomingEvents,
} from "../../components";
import { FaSistrix } from "react-icons/fa";
import { Link } from "react-router-dom";
import { headerVid, volunteerImg } from "../../assets";

const Home = () => {
  return (
    <>
      <section>
        <header>
          <video
            autoPlay
            loop
            muted
            playsInline
            id="hero-bgVid"
            className="header__bg-vid"
          >
            <source src={headerVid} type="video/mp4" />
          </video>

          <div className="header__wave"></div>
        </header>
      </section>
      <section id="feet">
        <div className="logo__mark">
          <div className="container">
            <article>
              <div>
                <h2>The future has feet</h2>
                <p>
                  The future of Ghana resides within its children, and it is our
                  mission at Our Desiree to nurture this future. Unfortunately,
                  poverty often reveals itself through the lack of basic
                  necessities, such as shoes and school bags. These children
                  face stigma and ridicule from their peers, leading to a loss
                  of self-esteem and a reluctance to attend school. At Our
                  Desiree, we believe that a happy soul is one with happy feet.
                  That's why we prioritize the provision of shoes as our primary
                  donation item. Together, we can restore joy and dignity to
                  these young lives.
                </p>
              </div>
            </article>
          </div>
        </div>
      </section>
      <section id="about">
        <div className="container">
          <article>
            <div>
              <h2>About Us</h2>
              <p>
                We want every child in Ghana to have no reason to not go to
                school
              </p>
              <p>
                We are an organization committed to a passion we share to reach
                out to needy children across the country and provide them with
                shoes, uniforms, school bags, and other essentials to make their
                childhood and schooling more exciting.
                <br />
                <br />
                It is no secret that a very noticeable sign of poverty is seen
                in the lack of a pair of decent shoes and bags etc.
                Unfortunately, children have to suffer excruciating stigma and
                ridicule from their mates for the lack of these. A feeling that
                steals away the joy and dignity of these kids causing them to
                lose their self-esteem and eventually shying away from school.
                The future as it stands is our children and what we feed them
                today will feed us tomorrow. In any possible way, we intend to
                feed them with hope, joy, and love and make schooling life fun
                and easier. We believe that a happy soul is one with happy feet
                for kids! And that is why shoes are the topmost item we donate.
              </p>
              <li>
                <Link to={"/about-us"} className="btnPrimary">
                  Learn more
                </Link>
              </li>
            </div>
          </article>
        </div>
      </section>

      {/* upcoming events */}
      {/* <section className="nextEvents">
        <div className="container">
          <UpcomingEvents />
          <div className="btnHolder">
            <Link to={"/events"} className="btnPrimary">
              View past events
            </Link>
          </div>
        </div>
      </section> */}

      <section id="founder">
        <div className="founder__image"></div>
      </section>
      <section id="founderText">
        <div className="container">
          <article>
            <div>
              <h2>Our Founder</h2>
              <p>
                Our Desiree, founded by Juaben Serwaah a selfless, thoughtful,
                enlightening, full of empathy, very accommodative, affable, God
                fearing and a goal getter who has a heart of gold..." Represents
                her commitment to helping the needy at all costs and helping to
                put smiles on their faces. Growing up, she will give out her
                food and sleep on an empty stomach. This act of generosity was
                fueled by her intense desire to see others happy. Hence the name
                of the Organization, "Our Desiree which means "desire it". Over
                the past six years, Juaben Serwaah’s birthdays have been
                celebrated at the orphanage home in Ghana to accomplish this
                mission.
              </p>
            </div>
          </article>
        </div>
      </section>
      <section id="volunteer">
        <div className="container">
          <div className="volunteer__content">
            <h1>Become a volunteer</h1>
            <p>
              Our Desiree is reaching out to people who have the same desire to
              join us in kind or in cash to make our mission a reality. We
              believe that’s what God wants us to do.
              <br />
              <br />
              Our arms are open to anyone who wants to help or join this
              organization to achieve its goals and objectives - putting a smile
              on the faces of kids and the less privileged. We can do this as a
              team. God bless our homeland Ghana.
            </p>

            <Link
              to={"/volunteer"}
              className="btnPrimary"
              style={{ backgroundColor: "#4fc5d7" }}
            >
              Become a volunteer
            </Link>
          </div>
          <div className="volunteer__image">
            <img src={volunteerImg} alt="" />
          </div>
        </div>
        <div className="volunteer__wave"></div>
      </section>
      <section id="team">
        <h2>Meet the team!</h2>
        <div className="container">
          <CardsRoller />
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Home;
