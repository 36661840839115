import React from "react";
import { NavLink,Link } from "react-router-dom";
import { footerMenuLinks, navigationLinks } from "./../../constants/links";
import { FaEnvelope, FaMapMarker, FaSistrix } from "react-icons/fa";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagramSquare,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";

import { LogoWhite } from "./../../assets";
import "./footer.css";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer>
        <div className="container">
          <div className="top">
            <div className="left">
              <div className="companyDetail">
                <div className="logo">
                  <img src={LogoWhite} alt="logo" />
                </div>
                <div className="description">
                  <p>
                    We envision a world where no child is forced to walk on
                    their bare feet due to poverty.
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="top">
                <div className="socials">
                  <Link
                    className="icons"
                    to={
                      "https://twitter.com/our_desiree?t=6MId_uH4RxXl0XFKz5EZHg&s=08"
                    }
                    target="_blank"
                  >
                    <FaTwitter className="icon" />
                  </Link>
                  <Link
                    className="icons"
                    to={
                      "https://instagram.com/our_desiree?utm_medium=copy_link"
                    }
                    target="_blank"
                  >
                    <FaInstagramSquare className="icon" />
                  </Link>
                  <Link
                    className="icons"
                    to={"https://www.facebook.com/OurDesiree"}
                    target="_blank"
                  >
                    <FaFacebookF className="icon" />
                  </Link>

                  <Link
                    className="icons"
                    to={"https://youtu.be/1IJtX5VY_1A"}
                    target="_blank"
                  >
                    <FaYoutube className="icon" />
                  </Link>
                  <Link
                    className="icons"
                    to={"https://vm.tiktok.com/ZMLYGFrDr/"}
                    target="_blank"
                  >
                    <FaTiktok className="icon" />
                  </Link>
                </div>
              </div>
              <div className="bottom">
                <div className="menuWrapper">
                  <div className="menuItem">
                    <div className="header">
                      <h3>{footerMenuLinks.supportLinks.title}</h3>
                    </div>
                    <div className="menuList">
                      {footerMenuLinks.supportLinks.links.map((links) => {
                        return (
                          <Link
                            className="service_menu_item"
                            key={links.id}
                            to={links.url}
                          >
                            {links.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                  <div className="menuItem">
                    <div className="header">
                      <h3>{footerMenuLinks.menuLinks.title}</h3>
                    </div>
                    <div className="menuList">
                      {footerMenuLinks.menuLinks.links.map((links) => {
                        return (
                          <Link
                            className="service_menu_item"
                            key={links.id}
                            to={links.url}
                          >
                            {links.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                  <div className="menuItem">
                    <div className="header">
                      <h3>contact us</h3>
                    </div>
                    <div className="address">
                      <article>
                        <p>
                          HSE NO. C1/9A <br />
                          NTHC SAKOMONO ESTATE <br />
                          Accra, Ghana <br />
                          support@ourdesiree.com
                        </p>
                        <div className="phoneNumbers">
                          <div>
                            <i className="bx bxs-phone"></i>
                            +233553007296
                          </div>
                          <div>
                            <i className="bx bxs-phone"></i>
                            +233549824571
                          </div>
                          <div>
                            <i className="bx bxs-phone"></i>
                            +233549824571
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            {/* <div className="search-bar">
              <form action="">
                <input type="text" name="search" placeholder="Search Here..." />
                <a className="searchButton" type="submit">
                  <FaSistrix className="searchIcon" />
                </a>
              </form>
            </div> */}
            <div className="menu-items">
              {navigationLinks.map((links) => {
                return (
                  <Link className="menu_item" key={links.id} to={links.url}>
                    {links.name}
                  </Link>
                );
              })}

              <NavLink to={"/donate"} className="footerDonateNavBtn">
                Donate
              </NavLink>
            </div>
            <div className="copyright">
              <div className="copy-name">
                &copy; {year} <Link to={"./"}> ourDesiree</Link> | Designed &
                developed by |
                <a
                  href="http://www.veltetech.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {""} Velte Technology Solutions
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
