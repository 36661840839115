import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./coming-events.css";
import { upcomingEvents } from "../../constants/data";
import { Link } from "react-router-dom";
import { FaCalendar, FaMapMarker, FaClock } from "react-icons/fa";
import { UpcomingEvents, ScrollToTop } from "../../components";

const ComingEvents = () => {
  const { upcomingEventId } = useParams();
  const comingEvent = upcomingEvents.find(
    (event) => event.id === upcomingEventId
  );

  return (
    <>
      <section
        className="upcomingDetailHeader"
        style={{ backgroundImage: `url(${comingEvent.image})` }}
      >
        <div className="blurBkg">
          <div className="container">
            <div className="eventHeader hor">
              <div className="left ver flex">
                <div className="eventTitle">
                  <h1>{comingEvent.title}</h1>
                </div>
                <div className="eventInfo">
                  <div className="infoItemD">
                    <p>
                      <FaCalendar className="icon" />
                      {comingEvent.date}
                    </p>
                  </div>
                  <div className="infoItemD">
                    <p>
                      <FaClock className="icon" />
                      {comingEvent.time}
                    </p>
                  </div>
                  <div className="infoItemD">
                    <p>
                      <FaMapMarker className="icon" />
                      {comingEvent.venue}
                    </p>
                  </div>
                </div>
                <div className="buyTickets">
                  <Link
                    to={"/donate"}
                    className="btn-white"
                    style={{ marginTop: "20px" }}
                  >
                    Donate
                  </Link>
                </div>
              </div>
              <div className="right ver flex">
                <div className="imgWrapper">
                  <img src={comingEvent.image} alt={comingEvent.title} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="eventDetail">
        <div className="container grayBackground">
          <div className="detailWrapper">
            <p>{comingEvent.details}</p>
          </div>
        </div>
      </section>
      <section className="eventsSection" style={{ marginBottom: "4rem" }}>
        <div className="container">
          <UpcomingEvents />
        </div>
      </section>

      <ScrollToTop />
    </>
  );
};

export default ComingEvents;
