import React from "react";
import { Routes, Route } from "react-router-dom";
import { router } from "./routes";
import {
  AboutUs,
  ContactUs,
  CookiePolicy,
  DonatePage,
  EventDetail,
  Events,
  Home,
  NotFoundPage,
  ComingEvents,
  OurTeam,
  PrivacyPolicy,
  TermsAndConditions,
  Volunteer,
} from "../pages";
import Layout from "../Layouts/Layout";
import EventDetailLayout from "../Layouts/EventDetailLayout";

const AppRouter = () => (
  //   <Routes>
  //     {router.map((route, index) => (
  //       <Route key={index} path={route.path} element={route.element}>
  //         {route.children &&
  //           route.children.map((childRoute, childIndex) => (
  //             <Route
  //               key={childIndex}
  //               path={childRoute.path}
  //               element={childRoute.element}
  //             />
  //           ))}
  //       </Route>
  //     ))}
  //   </Routes>
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index exact element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/our-team" element={<OurTeam />} />
      <Route path="/volunteer" element={<Volunteer />} />

      <Route path="/donate" element={<DonatePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

      <Route exact path="/events" element={<Events />} />

      <Route path="/events" element={<EventDetailLayout />}>
        <Route path="/events/past-events/:eventId" element={<EventDetail />} />
        <Route
          path="/events/upcoming-events/:upcomingEventId"
          element={<ComingEvents />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default AppRouter;
