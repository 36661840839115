import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import AppRouter from "./routes";

function App() {
  return (
    <div className="App">
      <SkeletonTheme baseColor="#d7d7d7" highlightColor="#bcbcbc">
        <AppRouter />
      </SkeletonTheme>
    </div>
  );
}

export default App;
