export const navigationLinks = [
  {
    id: "1",
    name: "home",
    url: "/",
  },
  {
    id: "2",
    name: "about us",
    url: "/about-us",
  },
  {
    id: "3",
    name: "events",
    url: "/events",
  },
  {
    id: "4",
    name: "our team",
    url: "/our-team",
  },

  {
    id: "5",
    name: "contact us",
    url: "/contact-us",
  },
];

export const footerMenuLinks = {
  supportLinks: {
    title: "support",
    links: [
      {
        id: "1",
        name: "privacy policy",
        url: "/privacy-policy",
      },
      {
        id: "2",
        name: "cookie policy",
        url: "/cookie-policy",
      },
      {
        id: "3",
        name: "terms and conditions",
        url: "/terms-and-conditions",
      },
    ],
  },
  menuLinks: {
    title: "menu",
    links: [
      {
        id: "1",
        name: "about us",
        url: "/about-us",
      },
      {
        id: "2",
        name: "past events ",
        url: "/events",
      },
      {
        id: "3",
        name: "volunteer",
        url: "/volunteer",
      },
      {
        id: "4",
        name: "contact us",
        url: "/contact-us",
      },
      {
        id: "5",
        name: "donate to us",
        url: "/donate",
      },
    ],
  },
};
