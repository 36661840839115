import React, { useState } from "react";
import "./our-team.css";
import { founderImg } from "../../assets";
import { teamMembers } from "../../constants/data";

const OurTeam = () => {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <section id="head">
        <div class="container">
          <div class="contents">
            <div class="left">
              <div class="top">
                <small>Meet</small>
                <h1>our team</h1>
              </div>
              <div class="bottom">
                <div class="backdrop"></div>
                <img src={founderImg} alt="our founder" />
              </div>
            </div>
            <div class="right">
              <p>
                Our Desiree, founded by
                <b>Juaben Serwaah</b>a selfless, thoughtful, enlightening, full
                of empathy, very accommodative, affable, God fearing and a goal
                getter who has a heart of gold..." Represents her commitment to
                helping the needy at all costs and helping to put smiles on
                their faces. Growing up, she will give out her food and sleep on
                an empty stomach.
                <p>
                  This act of generosity was fueled by her intense desire to see
                  others happy. Hence the name of the Organization, "Our Desiree
                  which means "desire it". Over the past six years, Juaben
                  Serwaah’s birthdays have been celebrated at the orphanage home
                  in Ghana to accomplish this mission.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="member-group" className="member-group">
        <div className="container">
          {teamMembers.map((member, index) => (
            <div className="member-profile" key={index}>
              <div className="left">
                <div className="profile-avatar">
                  <img src={member.image} alt={member.name} />
                </div>
              </div>
              <div className="right">
                <article>
                  <h3>{member.name}</h3>
                  <div>
                    <p>
                      {showMore
                        ? member.info
                        : `${member.info.substring(0, 550)}`}
                      {/* <button
                        className="btn"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </button> */}
                      {/* {member.info} */}
                    </p>
                  </div>
                </article>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default OurTeam;
