import React from "react";

const YoutubeFrame = ({ link }) => {
  return (
    <div className="vidHolder" style={{ width: "70%" }}>
      <div className="frameWrapper" style={{ width: "100%" }}>
        <iframe
          style={{ width: "100%", height: "420px" }}
          // width="720"
          // height="480"
          src={`${link}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="youtube video"
        />
      </div>
    </div>
  );
};

export default YoutubeFrame;
