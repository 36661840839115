import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";

import "./upcoming-events.css";
import { upcomingEvents } from "../../constants/data";
import { Link } from "react-router-dom";

const UpcomingEvents = () => {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <section className="upcomingEventsSlides">
        <div className="header">
          <h3>upcoming events</h3>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={50}
          loop={false}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            250: {
              slidesPerView: 1,
            },
            450: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
          }}
          navigation={true}
          modules={[Navigation, Autoplay, Pagination]}
          autoplay={{
            delay: 2790,
            disableOnInteraction: true,
          }}
          className="slide-swiper"
        >
          <div className="eventsSlideWrapper">
            {upcomingEvents.map((upNext, index) => (
              <SwiperSlide className="eventsSlide" key={index}>
                <Link
                  to={`/events/upcoming-events/${upNext.id}`}
                  key={upNext.id}
                >
                  <div className="event-slide">
                    <div className="image">
                      <div className="imgWrapper">
                        <img
                          loading="lazy"
                          src={upNext.image}
                          alt={upNext.title}
                        />
                      </div>
                    </div>
                    <div className="eventDetail">
                      <div className="Content">
                        <div className="header">
                          <h3>{upNext.title}</h3>
                        </div>
                        <div className="txtContent">
                          <p>{upNext.details}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </section>
    </>
  );
};

export default UpcomingEvents;
