import React from "react";
import "./events.css";
import { ScrollToTop, EventCard, UpcomingEvents } from "../../components";

const Events = () => {
  return (
    <>
      <section className="eventsSection">
        <div className="container">
          <div className="header">
            <h1>Events</h1>
          </div>
          {/* <UpcomingEvents /> */}
          <EventCard />
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Events;
