import React from "react";
import "./cards-roller.css";
import { teamMembers } from "../../constants/data";
import { Link } from "react-router-dom";

const CardsRoller = () => {
  return (
    <>
      <div className="outer">
        {teamMembers.map(
          (member) =>
            member.id < 4 && (
              <div
                className="card"
                // style={"--delay:" + member.delay + ";"}
                style={{ "--delay": member.delay }}
                key={member.id}
              >
                <Link to={"./our-team"} className="link">
                  <div class="card__content">
                    <div className="img">
                      <img src={member.image} alt={member.name} />
                    </div>
                    <div className="details">
                      <span className="name">{member.name}</span>
                      <p>{member.role}</p>
                    </div>
                  </div>
                </Link>
                {/* <a href="/">View</a> */}
              </div>
            )
        )}
      </div>
    </>
  );
};

export default CardsRoller;
