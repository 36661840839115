import {
  adubrimImg2,
  adubrimImg3,
  adubrimImg4,
  adubrimImg5,
  adubrimImg6,
  adubrimImg7,
  adubrimImg8,
  adubrimImg9,
  adubrimImg10,
  memberImg1,
  memberImg10,
  memberImg2,
  memberImg3,
  memberImg4,
  memberImg5,
  memberImg6,
  memberImg7,
  memberImg8,
  memberImg9,
  slideImg1,
  slideImg2,
  slideImg3,
  slideImg4,
  slideImg5,
  slideImg6,
  slideImg7,
  slideImg8,
  slideImg9,
  adubrimImg1,
  b2schFlyer,
  b2schImg1,
  b2schImg2,
  b2schImg3,
  b2schImg4,
  b2schImg5,
  b2schImg6,
  b2schImg7,
  b2schImg8,
  b2schImg9,
  upcomingEventImg1,
  kus1,
  kus2,
  kus3,
  kus10,
  kus11,
  kus12,
  kus13,
  kus14,
  kus15,
  kus16,
  kus17,
  kus18,
  kus19,
  kus20,
  kus21,
  kus22,
  kus23,
  kus24,
  kus25,
  kus26,
  kus27,
  kus28,
  kus29,
  kus9,
  kus7,
  kus5,
  kus4,
  kus6,
  kus8,
  wws1,
  wws10,
  wws11,
  wws12,
  wws13,
  wws14,
  wws15,
  wws16,
  wws6,
  wws5,
  wws4,
  wws3,
  wws2,
  wws7,
  wws8,
  wws9,
} from "../assets";

export const slider = [
  {
    image: slideImg1,
  },
  {
    image: slideImg2,
  },

  {
    image: slideImg3,
  },
  {
    image: slideImg4,
  },
  {
    image: slideImg5,
  },
  {
    image: slideImg6,
  },
  {
    image: slideImg7,
  },
  {
    image: slideImg8,
  },
  {
    image: slideImg9,
  },
];

export const teamMembers = [
  {
    id: "1",
    name: "Ms. Karen Adjoa Selasi",
    role: "Assistant Project Manager",
    image: memberImg1,

    delay: "-1",

    info: "Karen is the assistant project manager for our desiree and a creative director who is passionate about helping businesses and creative to thrive. \n\n As a young and vibrant lady, Ms Karen is currently employed with an Automobile Company as the creative director. She chose to join this organization after she interacted with Ms Juaben Serwah, the founder and fell in love with how passionate and determined she was to help put smiles on the faces of kids.",
  },
  {
    id: "2",
    name: "Ms. Winifred",
    role: "Membership Coordinator",
    image: memberImg2,

    delay: "1",

    info: "Winnie is the membership coordinator for Our Desiree and she functions as the liaison between the organization and it members.                                \n\n   Winnie is a civil servant and a company inspector.                                   She joined this organization because  she is so passionate about helping the kids,It’s heartwarming seeing these kids going to school with so much joy and fun.So she didn’t hesitate when Ms Serwaa asked her to join.",
  },
  {
    id: "3",
    name: "Samuel Ohene Sarpong",
    role: "Vice President",
    image: memberImg3,

    delay: "0",

    info: "Samuel Ohene Sarpong is the Vice President of Our Desiree and an IT Support Technician/CCTV Surveillance Analyst with vast knowledge of Security Systems.\n\n As a Security Personnel, Ohene is employed with Gold Fields Ghana Limited Tarkwa Site as a CCTV Surveillance Analyst. I joined Our Desiree Family through the founder Ms Juaben Serwaa because of her idea to help the less privilege kids across the country.",
  },

  // {
  //   id: "4",
  //   name: "Emmanuel Otu",
  //   role: "Membership coordinator",
  //   image: memberImg4,

  //   delay: "1",

  //   info: " Membership coordinator for “Our Desiree”, a network Engineer/administrator.\n\n It has always been the prayer of Emmanuel to be financially sound and also get the opportunity to bless the needy with the little he has. Joining this organization is a dream come true for him",
  // },
  // {
  //   id: "5",
  //   name: "Ms. Sandra Kristin Slater",
  //   role: "Assistant Secretary",
  //   image: memberImg5,

  //   delay: "2",

  //   info: "Assistant Secretary for “Our Desiree”, a student studying software engineering at the National Institute \n\n of Information Technology (NIIT) here in Ghana and also a remote makeup artist.\n\n As a family routine since childhood, Sandra and her family would visit the Osu orphanage here in Ghana  every month with commodities as donations.\n\n Sandra strongly believes that giving a helping hand and encouraging the needy is one of the best accomplishments spiritually, mentally and internally.\n\n As the Bible proclaims in James 1:27 “Religion that is pure and undefiled before God, the Father, is this:  to visit orphans and widows in their affliction, and to keep oneself unstained from the world”.\n\n  Also written in Proverbs 11:24-25 “One gives freely, yet grows all the richer; another withholds what he  should give, and only suffers want. Whoever brings blessing will be enriched, and one who waters will  himself be watered.” \n\n “Death is not our greatest loss in life, our greatest loss is what dies inside us as we live” is one of  Sandra’s favorite quotes. \n\n The above is what boosted her enthusiasm to support the needy so she gives with a cheerful heart to the hopeless and illuminate a dying soul. \n\n  Aside from her perception about giving, Sandra likes to meet new face and have fun, listen to music whiles taking a stroll, watch Disney movies, play solitaire/chess or watch coding tutorials.",
  // },
  // {
  //   id: "6",
  //   name: "ebenezer tetteh",
  //   role: "social media",
  //   image: memberImg6,

  //   delay: " 3",

  //   info: "Social media team member (Our Desiree)",
  // },
  // {
  //   id: "7",
  //   name: "Alexander Acheampong",
  //   role: "Membership coordinator",
  //   image: memberImg7,

  //   delay: "4",

  //   info: "Membership coordinator (Our Desiree), a Registered General Nurse at the Konfo Anokey Teaching  Hospital(Kumasi-Ghana), and love helping humanity.",
  // },
  // {
  //   id: "8",
  //   name: "Eric Acheampong",
  //   role: "Blogger",
  //   image: memberImg8,

  //   delay: "5",

  //   info: "A blogger and social media activist. \n\n Most often, the greatest legacy one can leave behind is your deeds when you were alive. \n\n Eric realized making someone smile and lifting them from their problems by supporting them in cash or kind is one of the greatest achievements. This informed his decision to join this organization so that collectively we can all put a smile on the faces of our little ones.",
  // },
  // {
  //   id: "9",
  //   name: "godfred d. nketia",
  //   role: "Social Media Manager",
  //   image: memberImg9,

  //   delay: "6",

  //   info: " Social Media Manager (Our Desiree), a blogger with much love for kids.",
  // },
  // {
  //   id: "10",
  //   name: "simon okyere",
  //   role: "Project Team",
  //   image: memberImg10,

  //   delay: "7",

  //   info: " Project Team (Our Desiree), a District manager for Cocoa Processing company(LBC) \n\n It has always been his heart’s desire to get the opportunity to help the needy and believes “our Desiree” gives that platform for a dream come true",
  // },
];

const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

export const projects = [
  {
    id: "1",
    image: adubrimImg1,
    title: "Adubrim",
    details:
      "On Thursday 9th December 2021, Our Desiree embarked on a 10hour Journey by road to the Ellembelle District in the Western Region to make donations to the pupils of Adubrim D/A KG, Primary, and JHS. ",
    location: "Adubrim, Ghana",

    date: {
      day: "09",
      month: "december",
      year: "2021",
    },

    youtubeURL: "https://www.youtube.com/embed/f-neutlUads",
    yt_title: "",
    pictures: [
      { src: adubrimImg2 },
      { src: adubrimImg3 },
      { src: adubrimImg4 },
      { src: adubrimImg5 },
      { src: adubrimImg6 },
      { src: adubrimImg7 },
      { src: adubrimImg8 },
      { src: adubrimImg9 },
      { src: adubrimImg10 },
    ],
  },
  {
    id: "2",
    image: b2schFlyer,
    title: "back 2 school",
    details:
      "Led and coordinated by Madam Juabeng Serwah, the team visited the Kwame Adwer D/A basic school on the 29th of July 2022. We donated Shoes, Bags, and Uniforms to the pupils of the school. The project saw success thanks to the support from volunteers and donors",
    location: "Kwame Adwer, central region, Ghana",
    date: {
      day: "29",
      month: "july",
      year: "2022",
    },

    youtubeURL: "https://www.youtube.com/embed/jyiWOgrJgWU",
    yt_title: "",
    pictures: [
      { src: b2schImg1 },
      { src: b2schImg2 },
      { src: b2schImg3 },
      { src: b2schImg4 },
      { src: b2schImg5 },
      { src: b2schImg6 },
      { src: b2schImg7 },
      { src: b2schImg8 },
      { src: b2schImg9 },
    ],
  },
  {
    id: "3",
    image: upcomingEventImg1,
    title: "kick you a smile",
    details:
      "Continuing our annual tradition, Our Desiree will be visiting Kwakukwaa School in the Eastern Region on July 14th. We will be donating shoes, bags, and clothes to the children. This year, we are adding extra excitement by incorporating games and fun activities, including face painting, dance competitions, sack races, rope competitions, and treats like cotton candy and popcorn. Together, we will create an unforgettable experience for these children while fostering their love for learning.",
    location: "Kwakukwa - Eastern Region",
    date: {
      day: "14",
      month: "july",
      year: "2023",
    },

    youtubeURL: "https://www.youtube.com/embed/syKwa_aF0-A",
    yt_title: "",
    pictures: [
      { src: kus1 },
      { src: kus2 },
      { src: kus3 },
      { src: kus4 },
      { src: kus5 },
      { src: kus6 },
      { src: kus7 },
      { src: kus8 },
      { src: kus9 },
      { src: kus10 },
      { src: kus11 },
      { src: kus12 },
      { src: kus13 },
      { src: kus14 },
      { src: kus15 },
      { src: kus16 },
      { src: kus17 },
      { src: kus18 },
      { src: kus19 },
      { src: kus20 },
      { src: kus21 },
      { src: kus22 },
      { src: kus23 },
      { src: kus24 },
      { src: kus25 },
      { src: kus26 },
      { src: kus27 },
      { src: kus28 },
      { src: kus29 },
    ],
  },
  {
    id: "4",
    image: wws1,
    title: "Walk with  a smile",
    details:
      "Continuing our annual tradition, Our Desiree will be visiting Kwakukwaa School in the Eastern Region on July 14th. We will be donating shoes, bags, and clothes to the children. This year, we are adding extra excitement by incorporating games and fun activities, including face painting, dance competitions, sack races, rope competitions, and treats like cotton candy and popcorn. Together, we will create an unforgettable experience for these children while fostering their love for learning.",
    location: "Kakraka  - Oti Region",
    date: {
      day: "12",
      month: "march",
      year: "2024",
    },

    youtubeURL: "",
    yt_title: "",
    pictures: [
      { src: wws2 },
      { src: wws3 },
      { src: wws4 },
      { src: wws5 },
      { src: wws6 },
      { src: wws7 },
      { src: wws8 },
      { src: wws9 },
      { src: wws10 },
      { src: wws11 },
      { src: wws12 },
      { src: wws13 },
      { src: wws14 },
      { src: wws15 },
      { src: wws16 },
    ],
  },
];

export const past_events = shuffle(projects);

export const upcomingEvents = [
  {
    id: "1",
    image: upcomingEventImg1,
    title: "kick you a smile",
    details:
      "Continuing our annual tradition, Our Desiree will be visiting Kwakukwaa School in the Eastern Region on July 14th. We will be donating shoes, bags, and clothes to the children. This year, we are adding extra excitement by incorporating games and fun activities, including face painting, dance competitions, sack races, rope competitions, and treats like cotton candy and popcorn. Together, we will create an unforgettable experience for these children while fostering their love for learning.",
    venue: "Kwakukwa - Eastern Region",
    date: "14th July 2023",
    time: "9am",
  },
  {
    id: "2",
    image: upcomingEventImg1,
    title: "event 2",
    details:
      "Continuing our annual tradition, Our Desiree will be visiting Kwakukwaa School in the Eastern Region on July 14th. We will be donating shoes, bags, and clothes to the children. This year, we are adding extra excitement by incorporating games and fun activities, including face painting, dance competitions, sack races, rope competitions, and treats like cotton candy and popcorn. Together, we will create an unforgettable experience for these children while fostering their love for learning.",
    venue: "Kwakukwa - Eastern Region",
    date: "14th July 2023",
    time: "9am",
  },
];
