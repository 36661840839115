import React from "react";
import App from "./App";
import { getTitleFromRoute } from "./utils/docTitle";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const AppWrapper = () => {
  const location = useLocation();
  return (
    <div>
      <Helmet>
        <title>{getTitleFromRoute(location.pathname)}</title>
      </Helmet>
      <App />
    </div>
  );
};

export default AppWrapper;
